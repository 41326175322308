<template>
  <div class="">
    <b-modal
      has-modal-card
      :can-cancel="[]"
      :width="600"
      v-model="isModalNeedPayActive"
    >
      <div class="need-pay box">
        <p>{{ message }}</p>
        <div class="button-wrapper">
          <b-button type="is-info" outlined @click.prevent="logout" v-if="!role"
            >Продолжить</b-button
          >
          <b-button type="is-primary" v-if="role" @click.prevent="goToPay"
            >Перейти к оплате</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalNeedPay',
  computed: {
    isModalNeedPayActive: {
      get() {
        return this.$store.state.isModalNeedPayActive;
      },
      set(active) {
        this.$store.commit('toggleModalNeedPay', active);
      },
    },
    message() {
      return this.$store.state.getme.message || '';
    },
    role() {
      return this.$store.state.getme.user
        ? this.$store.state.getme.user.role
        : false;
    },
  },
  methods: {
    async logout() {
      this.$store.commit('toggleLoader', true);
      await this.$store.dispatch('logout');
      this.$store.commit('toggleLoader', false);
      this.$router.push('/login');
    },
    goToPay() {
      this.$router.push('/admin/profile');
    },
  },
};
</script>

<style lang="scss">
.modal {
  .need-pay {
    position: relative;

    .button-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
