import axios from 'axios';
import router from '@/router';

const API = {
  checkRedirect(data) {
    if ('redirect' in data) {
      router.push(data.redirect);
    }
  },
  async getme() {
    try {
      const response = await axios.post('/api/get_me');

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async search(clientData) {
    try {
      const response = await axios.post('/api/search', clientData);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async login(userData) {
    try {
      const response = await axios.post('/api/login', { userData });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async logout() {
    try {
      const response = await axios.post('/api/logout');
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async restorePassword(email) {
    try {
      const response = await axios.post('/api/send_restore_mail', {
        email: email,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async changeUserPassword(userData) {
    try {
      const response = await axios.post('/api/change_password', userData);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async changeEmployeePassword(userData) {
    try {
      const response = await axios.post(
        '/api/change_employee_password',
        userData
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async newClient(data) {
    try {
      const response = await axios.post('/api/new_client', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getClient({ id, csrf }) {
    try {
      const response = await axios.post('/api/get_client_by_id', {
        id,
        csrf,
      });

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async deleteClient({ id, csrf }) {
    try {
      const response = await axios.post('/api/delete_client', {
        id,
        csrf,
      });

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async updateClient(data) {
    try {
      const response = await axios.post('/api/update_client', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getClientContact({ id, csrf }) {
    try {
      const response = await axios.post('/api/get_client_contact_by_id', {
        id,
        csrf,
      });

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async setCongratulation(id) {
    try {
      const response = await axios.post('api/set_congratulation', id);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async newPolis(data) {
    try {
      const response = await axios.post('/api/new_polis', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async updatePolis(data) {
    try {
      const response = await axios.post('/api/update_polis', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async polisCancelled(data) {
    try {
      const response = await axios.post('/api/set_polis_cancelled', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async polisToRenew(id) {
    try {
      const response = await axios.post('/api/set_polis_to_renew', id);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async renewPolis(data) {
    try {
      const response = await axios.post('/api/renew_polis', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async beforeRenewPolis(data) {
    try {
      const response = await axios.post('/api/before_renew_polis', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getExpiredPolises(csrf) {
    try {
      const response = await axios.post('/api/get_expired_polises', csrf);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getExpiresPolises(csrf) {
    try {
      const response = await axios.post('/api/get_expires_polises', csrf);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getRenewPolises(csrf) {
    try {
      const response = await axios.post('/api/get_polises_to_renew', csrf);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getBirthdays(csrf) {
    try {
      const response = await axios.post('/api/get_birthdays', csrf);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async deleteCar(data) {
    try {
      const response = await axios.post('/api/delete_car', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async newUser(data) {
    try {
      const response = await axios.post('/api/new_user', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async newEmployee(data) {
    try {
      const response = await axios.post('/api/new_employee', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async uploadFile(formdata) {
    try {
      const response = await axios({
        url: '/api/upload_client_document',
        method: 'post',
        data: formdata,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async deleteFile(data) {
    try {
      const response = await axios.post('/api/delete_client_file', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async profileInfo() {
    try {
      const response = await axios.post('/api/profile_info');

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getStatistic(csrf) {
    try {
      const response = await axios.post('/api/get_stat', csrf);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async createPayment(data) {
    try {
      const response = await axios.post('/api/payment/create_payment', data);

      this.checkRedirect(response.data);

      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default API;
