import Vue from 'vue';
import VueRouter from 'vue-router';
import Workspace from '../views/Workspace.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import('../views/Page404.vue'),
    meta: {
      layout: 'GuestLayout',
    },
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: Workspace,
  },
  {
    path: '/admin',
    redirect: '/admin/profile',
  },
  {
    path: '/admin/profile',
    name: 'AdminProfileInfo',
    component: () => import('../views/AdminProfileInfo.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/admin/employees',
    name: 'AdminEmployees',
    component: () => import('../views/AdminEmployees.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/admin/statistic',
    name: 'AdminStatistic',
    component: () => import('../views/AdminStatistic.vue'),
    meta: {
      layout: 'AdminLayout',
    },
  },
  {
    path: '/statistic',
    name: 'UserStatistic',
    component: () => import('../views/UserStatistic.vue'),
    meta: {
      layout: 'ProfileLayout',
    },
  },
  {
    path: '/create-employee',
    name: 'NewEmployee',
    component: () => import('../views/NewEmployee.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/polises-utils',
    name: 'PolisesUtils',
    component: () => import('../views/PolisesUtils.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/new-client/physical',
    name: 'NewClientPhysical',
    component: () => import('../views/NewClientPhysical.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/new-client/entity',
    name: 'NewClientEntity',
    component: () => import('../views/NewClientEntity.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/new-client/non-resident',
    name: 'NewClientNonResident',
    component: () => import('../views/NewClientNonResident.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/update-client/physical/:id',
    name: 'UpdateClientPhysical',
    component: () => import('../views/UpdateClientPhysical.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/update-client/entity/:id',
    name: 'UpdateClientEntity',
    component: () => import('../views/UpdateClientEntity.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/update-client/non-resident/:id',
    name: 'UpdateClientNonResident',
    component: () => import('../views/UpdateClientNonResident.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/clients/:id',
    name: 'Client',
    component: () => import('../views/Client.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/clients/:id/new-polis',
    name: 'NewPolis',
    component: () => import('../views/NewPolis.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/clients/:id/update-polis/:polisId',
    name: 'UpdatePolis',
    component: () => import('../views/UpdatePolis.vue'),
    meta: {
      layout: 'CreateLayout',
    },
  },
  {
    path: '/user',
    redirect: '/user/profile',
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile.vue'),
    meta: {
      layout: 'ProfileLayout',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/restore-password',
    name: 'RestorePassword',
    component: () => import('../views/RestorePassword.vue'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/tariffs',
    name: 'Tariffs',
    component: () => import('../views/Tariffs.vue'),
    meta: {
      layout: 'GuestLayout',
    },
  },
  {
    path: '/',
    name: 'Promo',
    component: () => import('../views/Promo.vue'),
    meta: {
      layout: 'GuestLayout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // этот путь требует авторизации, проверяем залогинен ли
//     // пользователь, и если нет, перенаправляем на страницу логина
//     if (!auth.loggedIn()) {
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath },
//       });
//     } else {
//       next();
//     }
//   } else {
//     next(); // всегда так или иначе нужно вызвать next()!
//   }
// });

// router.push('/admin').catch((failure) => {
//   if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
//     console.log(failure.to.path); // '/admin'
//     console.log(failure.from.path); // '/'
//   }
// });

export default router;
