import API from '@/api';
import router from '@/router';
import { ToastProgrammatic as Toast } from 'buefy';

const employee = {
  namespaced: true,
  state: () => ({
    noValid: {},
    employees: [],
  }),
  mutations: {
    setNoValid(state, noValid) {
      state.noValid = noValid;
    },
    setEmployees(state, employees) {
      state.employees = employees;
    },
  },
  actions: {
    async newEmployee({ rootState, commit, dispatch }, employeeData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.newEmployee({
        employeeData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('newEmployee', employeeData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && !('noValid' in data)) {
        Toast.open({
          duration: 5000,
          message: 'Сотрудник успешно создан',
          type: 'is-success',
          position: 'is-bottom-right',
        });

        router.push('/admin/employees');
      }

      commit('toggleLoader', false, { root: true });
    },
  },
  getters: {},
};

export default employee;
