<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Загрузить документ клиента</p>
    </header>
    <div class="card-content">
      <div class="content">
        <b-field label="Название файла">
          <b-input placeholder="Паспорт" v-model="type"></b-input>
        </b-field>
        <b-field class="file">
          <b-upload v-model="file" expanded>
            <a class="button is-info is-fullwidth">
              <b-icon icon="upload"></b-icon>
              <span>{{ file.name || 'Загрузить файл' }}</span>
            </a>
          </b-upload>
        </b-field>
      </div>
    </div>
    <footer class="card-footer">
      <b-button class="card-footer-item" @click.prevent="$emit('close')">
        Закрыть
      </b-button>
      <b-button
        type="is-success"
        class="card-footer-item"
        @click.prevent="submitFile"
      >
        Сохранить
      </b-button>
    </footer>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ModalUploadFile',
  props: ['clientId'],
  data() {
    return {
      type: '',
      file: {},
    };
  },
  methods: {
    async submitFile() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const success = await this.$store.dispatch('client/uploadFile', {
        file: this.file,
        type: this.type,
        id: this.clientId,
      });

      if (success) {
        this.$emit('close');
      }
    },
  },
  validations: {
    type: {
      required,
    },
    file: {
      required,
    },
  },
  beforeDestroy() {
    this.type = '';
    this.file = {};
  },
};
</script>
