<template>
  <div class="client">
    <section class="panel is-primary" v-if="showInfo">
      <h1 class="title is-6 panel-heading mb-0">Информация о клиенте</h1>
      <component :is="clientType" :clientData="clientData"></component>
      <div class="client-data box">
        <b-tabs v-model="activeTab">
          <b-tab-item icon="car" label="Автомобили">
            <TableCars :cars="clientData.cars" :clientId="clientId" />
          </b-tab-item>
          <b-tab-item icon="file-medical" label="Полисы">
            <TablePolises :polises="clientData.polises" :clientId="clientId" />
            <div class="buttons-wrapper">
              <b-button
                type="is-success"
                tag="router-link"
                :to="`/clients/${clientId}/new-polis`"
                >Добавить полис</b-button
              >
            </div>
          </b-tab-item>
          <b-tab-item icon="file-download" label="Файлы">
            <BlockFiles :files="clientData.files" :clientId="clientId" />

            <div class="buttons-wrapper">
              <b-button
                type="is-link"
                @click.prevent="isModalUploadFielActive = true"
                >Добавить документ</b-button
              >
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
    <b-message type="is-danger" has-icon v-else>Клиент не найден</b-message>

    <b-modal
      class="modal-upload-file"
      :width="600"
      v-model="isModalUploadFielActive"
    >
      <template #default="props">
        <ModalUploadFile :clientId="clientId" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import ModalUploadFile from '@/components/client/ModalUploadFile';
import TableCars from '@/components/client/TableCars';
import TablePolises from '@/components/client/TablePolises';
import BlockFiles from '@/components/client/BlockFiles';

export default {
  name: 'Client',
  props: ['id'],
  data() {
    return {
      isModalUploadFielActive: false,
      activeTab: 0,
      clientId: this.id || this.$route.params.id,
    };
  },
  async created() {
    if (this.id === undefined) {
      this.$store.commit('toggleLoader', true);
      await this.$store.dispatch('getme');
    }

    this.$store.dispatch('client/getClient', this.clientId);
  },
  computed: {
    clientData() {
      return this.$store.state.client.clientData;
    },
    showInfo() {
      return Object.keys(this.clientData).length;
    },
    clientType() {
      let componentName = '';

      switch (Number(this.clientData.type)) {
        case 1:
          componentName = 'Physical';
          break;

        case 2:
          componentName = 'Entity';
          break;

        case 3:
          componentName = 'NonResident';
          break;

        default:
          componentName = 'Physical';
          break;
      }

      return () => import(`@/components/client/${componentName}.vue`);
    },
  },
  methods: {
    // пока не используется
    iconType(file) {
      let icon = 'file-download';
      let type = file.split('.');
      type = type[type.length - 1].toLowerCase();

      switch (type) {
        case 'pdf':
          icon = 'file-pdf';
          break;

        case 'png':
        case 'jpg':
        case 'svg':
          icon = 'file-image';
          break;

        case 'doc':
        case 'docx':
          icon = 'file-word';
          break;

        case 'ppt':
        case 'pptx':
          icon = 'file-powerpoint';
          break;

        case 'zip':
        case '7z':
        case 'rar':
          icon = 'file-archive';
          break;

        default:
          break;
      }

      return icon;
    },
  },
  components: {
    ModalUploadFile,
    TableCars,
    TablePolises,
    BlockFiles,
  },
};
</script>

<style lang="scss">
.client {
  height: 100%;

  section.panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f9f9f9;

    .client-info {
      h6 {
        font-weight: bold;
      }
    }

    .client-data {
      padding: 30px 20px;

      .panel-icon {
        font-size: 20px;
      }

      .tabs {
        .icon {
          font-size: 20px;
        }
      }

      .tab-content {
        padding: 0;
      }

      .file-download {
        .panel-block {
          justify-content: space-between;
        }

        .file-download-link {
          color: #496ca2;
          font-weight: 500;

          &:hover {
            color: #1d4075;
          }

          .panel-icon {
            color: #496ca2;
          }
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      padding: 20px;
      gap: 20px;
    }
  }

  .message {
    margin: 0;

    .media {
      align-items: center;
    }
  }

  .modal-upload-file {
    .card {
      .card-footer {
        button {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
