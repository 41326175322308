<template>
  <div class="file-download">
    <div class="panel-block" v-for="(file, key) in filesData" :key="key">
      <a
        :href="`/api/get_client_file/${clientId}/${file}`"
        download
        class="file-download-link"
      >
        <span class="panel-icon">
          <i class="fas fa-download" aria-hidden="true"></i>
        </span>
        {{ file }}
      </a>
      <div class="button-delete-file">
        <b-button
          size="is-small"
          type="is-danger"
          icon-left="trash-alt"
          @click.prevent="deleteFile(file, clientId)"
        >
          Удалить
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockFiles',
  props: ['files', 'clientId'],
  data() {
    return {
      filesData: this.files || {},
    };
  },
  watch: {
    files(data) {
      this.filesData = data;
    },
  },
  methods: {
    async deleteFile(fileName, clientId) {
      const success = await this.$store.dispatch('client/deleteFile', {
        fileName,
        clientId,
      });

      if (success) {
        const newData = { ...this.filesData };

        for (const key in newData) {
          if (Object.hasOwnProperty.call(newData, key)) {
            const file = newData[key];

            if (file === fileName) {
              delete newData[key];
            }
          }
        }

        this.filesData = newData;
      }
    },
  },
};
</script>

<style lang="sass"></style>
