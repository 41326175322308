import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from './App.vue';

import router from './router';
import store from './store';

import Buefy from 'buefy';
import './assets/scss/_custom.scss';
import './assets/scss/_common.scss';

Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
