import API from '@/api';
import router from '@/router';
import { ToastProgrammatic as Toast } from 'buefy';

const client = {
  namespaced: true,
  state: () => ({
    noValid: {},
    clientData: {},
  }),
  mutations: {
    setNoValid(state, noValid) {
      state.noValid = noValid;
    },
    setClientData(state, clientData) {
      state.clientData = clientData;
    },
  },
  actions: {
    async newClient({ rootState, commit, dispatch }, clientData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.newClient({
        clientData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('newClient', clientData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && 'id' in data) {
        router.push('/clients/' + data.id);
      }

      commit('toggleLoader', false, { root: true });
    },
    async getClient({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getClient({
        id,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getClient', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (Array.isArray(data.client)) {
        data.client = {};
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('setClientData', data.client);
      commit('toggleLoader', false, { root: true });
    },
    async getClientContact({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getClientContact({
        id,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getClientContact', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (Array.isArray(data.client)) {
        data.client = {};
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.client;
    },
    async uploadFile({ rootState, commit, dispatch }, fileData) {
      commit('toggleLoader', true, { root: true });

      const formData = new FormData();

      formData.append('file', fileData.file);
      formData.append('type', fileData.type);
      formData.append('id', fileData.id);
      formData.append('csrf', rootState.csrf);

      const data = await API.uploadFile(formData);

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('uploadFile', fileData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Файл успешно сохранен',
          type: 'is-success',
          position: 'is-bottom-right',
        });

        dispatch('getClient', fileData.id);
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });

        commit('toggleLoader', false, { root: true });
      }

      return data.success;
    },
    async deleteFile({ rootState, commit, dispatch }, { clientId, fileName }) {
      commit('toggleLoader', true, { root: true });

      const data = await API.deleteFile({
        id: clientId,
        fileName: fileName,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('deleteFile', { clientId, fileName });
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Файл удален',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async deleteCar({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.deleteCar({
        id,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('deleteCar', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Автомобиль удален',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async deleteClient({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.deleteClient({
        id: id.id,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('deleteClient', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success === false) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: 'Клиент успешно удален',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      }
      commit('toggleLoader', false, { root: true });
      return true;
    },
    async updateClient({ rootState, commit, dispatch }, clientData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.updateClient({
        clientData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('updateClient', clientData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && 'id' in data) {
        router.push('/clients/' + data.id);
      }

      commit('toggleLoader', false, { root: true });
    },
    async getBirthdays({ rootState, commit, dispatch }) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getBirthdays({
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getBirthdays');
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return 'birthdays' in data ? data.birthdays : [];
    },
    async setCongratulation({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.setCongratulation({
        csrf: rootState.csrf,
        id,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('setCongratulation', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });
    },
  },
  getters: {},
};

export default client;
