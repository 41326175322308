<template>
  <div class="workspace">
    <div
      class="has-text-centered"
      v-if="
        !tablePhysicalData.length &&
        !tableEntityData.length &&
        !tableNonResidentData.length
      "
    >
      <b-icon icon="search" size="is-medium" type="is-primary"></b-icon>
      <b>Здесь будут отображаться результаты поиска</b>
    </div>

    <TablePhysical
      v-if="tablePhysicalData.length"
      :tableData="tablePhysicalData"
      @row-click="clickRow"
    />
    <TableEntity
      v-if="tableEntityData.length"
      :tableData="tableEntityData"
      @row-click="clickRow"
    />
    <TableNonResident
      v-if="tableNonResidentData.length"
      :tableData="tableNonResidentData"
      @row-click="clickRow"
    />

    <b-modal :width="1280" v-model="isModalClientActive">
      <Client :id="clientId" />
    </b-modal>

    <ModalNeedPay />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TablePhysical from '@/components/workspace/TablePhysical.vue';
import TableEntity from '@/components/workspace/TableEntity.vue';
import TableNonResident from '@/components/workspace/TableNonResident.vue';
import ModalNeedPay from '@/components/workspace/ModalNeedPay.vue';

import Client from '@/views/Client';

export default {
  name: 'Workspace',
  data() {
    return {
      isModalClientActive: false,
      clientId: null,
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    ...mapState({
      result: (state) => {
        return state.search.clientData;
      },
    }),
    tablePhysicalData() {
      return this.result.filter((item) => item.type == '1');
    },
    tableEntityData() {
      return this.result.filter((item) => item.type == '2');
    },
    tableNonResidentData() {
      return this.result.filter((item) => item.type == '3');
    },
  },
  methods: {
    clickRow(data) {
      this.clientId = data.id;
      this.isModalClientActive = true;
    },
  },
  components: {
    Client,
    TablePhysical,
    TableEntity,
    TableNonResident,
    ModalNeedPay,
  },
};
</script>

<style lang="scss">
.workspace {
  .table-wrapper {
    table {
      tbody {
        tr {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
