<template>
  <b-table
    :data="tableData"
    :bordered="false"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
    @click="clickRow"
  >
    <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
      {{ props.row.id }}
    </b-table-column>

    <b-table-column field="lastName" label="Фамилия" v-slot="props">
      {{ props.row.lastName }}
    </b-table-column>

    <b-table-column field="firstName" label="Имя" v-slot="props">
      {{ props.row.firstName }}
    </b-table-column>

    <b-table-column field="isPassport" label="Вид документа" v-slot="props">
      {{ Number(props.row.isPassport) ? 'Паспорт' : 'Вид на жительство' }}
    </b-table-column>

    <b-table-column field="passport" label="Серия и номер" v-slot="props">
      {{ props.row.passportSeries + ' ' + props.row.passportNumber }}
    </b-table-column>

    <b-table-column
      field="link"
      label="Перейти"
      width="170"
      centered
      v-slot="props"
    >
      <b-button
        size="is-small"
        type="is-link"
        tag="router-link"
        :to="`/clients/${props.row.id}`"
        >Перейти к клиенту</b-button
      >
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">
        <b-icon icon="ban" size="is-medium" type="is-danger"></b-icon>
        <b>Ничего не найдено</b>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TableNonResident',
  props: ['tableData'],
  methods: {
    clickRow(data) {
      this.$emit('row-click', data);
    },
  },
};
</script>
