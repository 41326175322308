import API from '@/api';
import router from '@/router';
import { ToastProgrammatic as Toast } from 'buefy';

const polis = {
  namespaced: true,
  state: () => ({
    noValid: {},
    polisData: {},
  }),
  mutations: {
    setNoValid(state, noValid) {
      state.noValid = noValid;
    },
    setPolisData(state, data) {
      state.polisData = data;
    },
  },
  actions: {
    async newPolis({ rootState, commit, dispatch }, polisData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.newPolis({
        polisData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('newPolis', polisData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && !('noValid' in data)) {
        router.push('/clients/' + polisData.clientId);
      }

      commit('toggleLoader', false, { root: true });
    },
    async updatePolis({ rootState, commit, dispatch }, polisData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.updatePolis({
        polisData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('updatePolis', polisData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && !('noValid' in data)) {
        router.push('/clients/' + polisData.clientId);
      }

      commit('toggleLoader', false, { root: true });
    },
    async polisCancelled(
      { rootState, commit, dispatch },
      { polisId, clientId }
    ) {
      commit('toggleLoader', true, { root: true });

      const data = await API.polisCancelled({
        polisId,
        clientId,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('polisCancelled', { polisId, clientId });
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Полис отменен',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async polisToRenew({ rootState, commit, dispatch }, id) {
      commit('toggleLoader', true, { root: true });

      const data = await API.polisToRenew({
        id,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('polisToRenew', id);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Полис перенесен к продлению',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async beforeRenewPolis(
      { rootState, commit, dispatch },
      { polisId, clientId }
    ) {
      commit('toggleLoader', true, { root: true });

      const data = await API.beforeRenewPolis({
        polisId,
        clientId,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('beforeRenewPolis', { polisId, clientId });
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        commit('setPolisData', data.data);
      } else {
        commit('setPolisData', {});

        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async renewPolis({ rootState, commit, dispatch }, polisData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.renewPolis({
        polisData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('renewPolis', polisData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if ('noValid' in data) {
        commit('setNoValid', Object.freeze(data.noValid));
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      if (data.success && !('noValid' in data)) {
        Toast.open({
          duration: 5000,
          message: 'Полис успешно продлен',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success && !('noValid' in data);
    },
    async getExpiredPolises({ rootState, commit, dispatch }) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getExpiredPolises({
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getExpiredPolises');
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return 'polises' in data ? data.polises : [];
    },
    async getExpiresPolises({ rootState, commit, dispatch }) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getExpiresPolises({
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getExpiresPolises');
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return 'polises' in data ? data.polises : [];
    },
    async getRenewPolises({ rootState, commit, dispatch }) {
      commit('toggleLoader', true, { root: true });

      const data = await API.getRenewPolises({
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('getRenewPolises');
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (!data.success) {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return 'polises' in data ? data.polises : [];
    },
  },
  getters: {},
};

export default polis;
