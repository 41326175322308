<template>
  <div id="app">
    <LoaderFull v-if="loading" />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderFull from '@/components/app/LoaderFull.vue';

export default {
  components: {
    LoaderFull,
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
    layout() {
      const layoutName = this.$route.meta.layout || 'MainLayout';

      return () => import(`@/layouts/${layoutName}.vue`);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
