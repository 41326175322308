import API from '@/api';
import { ToastProgrammatic as Toast } from 'buefy';

const profile = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async changeEmployeePassword({ rootState, commit, dispatch }, userData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.changeEmployeePassword({
        userData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('changeEmployeePassword', userData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Пароль успешно изменен',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async changeUserPassword({ rootState, commit, dispatch }, userData) {
      commit('toggleLoader', true, { root: true });

      const data = await API.changeUserPassword({
        userData,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('changeUserPassword', userData);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success) {
        Toast.open({
          duration: 5000,
          message: 'Пароль успешно изменен',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
    async createPayment({ rootState, commit, dispatch }, amount) {
      commit('toggleLoader', true, { root: true });

      const data = await API.createPayment({
        amount,
        csrf: rootState.csrf,
      });

      if ('response' in data && data.response.status === 500) {
        Toast.open({
          duration: 5000,
          message:
            data.response.data.message ||
            'Произошла ошибка. Попробуйте еще раз!',
          position: 'is-bottom-right',
          type: 'is-danger',
        });

        commit('toggleLoader', false, { root: true });

        return false;
      }

      if ('response' in data && data.response.status === 412) {
        await dispatch('getme', null, { root: true });
        dispatch('createPayment', amount);
        return;
      }

      if ('csrf' in data) {
        commit('setCSRF', data.csrf, { root: true });
      }

      if (data.success && 'redirectUrl' in data) {
        window.location.href = data.redirectUrl;
      } else {
        Toast.open({
          duration: 5000,
          message: data.message,
          type: 'is-danger',
          position: 'is-bottom-right',
        });
      }

      commit('toggleLoader', false, { root: true });

      return data.success;
    },
  },
  getters: {},
};

export default profile;
