<template>
  <b-table
    :data="carsData"
    :bordered="false"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
  >
    <b-table-column field="marka" label="Марка" v-slot="props">
      {{ props.row.marka }}
    </b-table-column>

    <b-table-column field="model" label="Модель" v-slot="props">
      {{ props.row.model }}
    </b-table-column>

    <b-table-column field="carNumber" label="Гос номер" v-slot="props">
      {{ props.row.carNumber }}
    </b-table-column>

    <b-table-column field="vin" label="VIN код" v-slot="props">
      {{ props.row.vin !== null ? props.row.vin : 'Отсутствует' }}
    </b-table-column>

    <b-table-column
      field="action"
      label="Действие"
      width="150"
      centered
      v-slot="props"
    >
      <b-button
        size="is-small"
        type="is-danger is-light"
        outlined
        v-if="props.row.vin == null"
        @click.prevent="deleteCar(props.row.id)"
        >Удалить авто</b-button
      >
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">Автомобилей не найдено</div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TableCars',
  props: ['cars', 'clientId'],
  data() {
    return {
      carsData: this.cars || [],
    };
  },
  watch: {
    cars(data) {
      this.carsData = data;
    },
  },
  methods: {
    async deleteCar(id) {
      const success = await this.$store.dispatch('client/deleteCar', id);

      if (success) {
        this.carsData = this.carsData.filter((item) => item.id != id);
      }
    },
  },
};
</script>

<style lang="scss"></style>
