<template>
  <b-table
    :data="polisesData"
    :bordered="false"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
  >
    <b-table-column field="type" label="Тип полиса" v-slot="props">
      {{ convertPolisType(props.row.type) }}
    </b-table-column>

    <b-table-column field="series" label="Серия полиса" v-slot="props">
      {{ props.row.series }}
    </b-table-column>

    <b-table-column field="number" label="Номер полиса" v-slot="props">
      {{ props.row.number }}
    </b-table-column>

    <b-table-column
      field="insuranceName"
      label="Название страховой"
      v-slot="props"
    >
      {{ props.row.insuranceName }}
    </b-table-column>

    <b-table-column field="startDate" label="Дата выдачи" v-slot="props">
      {{ formatDateTime(props.row.startDate) }}
    </b-table-column>

    <b-table-column field="endDate" label="Дата окончания" v-slot="props">
      {{ formatDateTime(props.row.endDate) }}
    </b-table-column>

    <b-table-column field="status" label="Статус" centered v-slot="props">
      <b-tag :type="tagClassStatus(props.row.status)">
        {{ convertPolisStatus(props.row.status) }}</b-tag
      >
    </b-table-column>

    <b-table-column
      field="action"
      label="Действие"
      width="150"
      centered
      v-slot="props"
    >
      <b-button
        class="update-polis"
        size="is-small"
        type="is-warning"
        tag="router-link"
        :to="`/clients/${clientId}/update-polis/${props.row.id}`"
      >
        Редактировать
      </b-button>

      <b-button
        size="is-small"
        type="is-danger is-light"
        outlined
        v-if="Number(props.row.status)"
        @click.prevent="polisCancelled(props.row.id)"
        >Отменить полис</b-button
      >
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">Полисы отсутствуют</div>
    </template>
  </b-table>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'TablePolises',
  props: ['polises', 'clientId'],
  data() {
    return {
      polisesData: this.polises || [],
    };
  },
  watch: {
    polises(data) {
      this.polisesData = data;
    },
  },
  methods: {
    convertPolisType(type) {
      let typeText = '';

      switch (Number(type)) {
        case 1:
          typeText = 'Страхование ОСАГО';
          break;

        case 2:
          typeText = 'Страхование КАСКО';
          break;

        case 3:
          typeText = 'Страхование Имущества';
          break;

        case 4:
          typeText = 'Страхование Жизни';
          break;

        default:
          break;
      }

      return typeText;
    },
    tagClassStatus(status) {
      let tagClass = '';

      switch (Number(status)) {
        case 0:
          tagClass = 'is-danger';
          break;

        case 1:
          tagClass = 'is-success';
          break;
        case 2:
          tagClass = 'is-info';
          break;

        default:
          break;
      }

      return tagClass;
    },
    convertPolisStatus(status) {
      let statusText = '';

      switch (Number(status)) {
        case 0:
          statusText = 'Отменен';
          break;

        case 1:
          statusText = 'Создан';
          break;

        case 2:
          statusText = 'Выдан';
          break;

        case 3:
          statusText = '';
          break;

        case 4:
          statusText = '';
          break;

        default:
          break;
      }

      return statusText;
    },
    formatDateTime(date) {
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
    async polisCancelled(id) {
      const success = await this.$store.dispatch('polis/polisCancelled', {
        clientId: this.clientId,
        polisId: id,
      });

      if (success) {
        const index = this.polisesData.findIndex((item) => item.id == id);
        this.polisesData[index].status = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.table {
  .update-polis {
    margin-bottom: 10px;
  }
}
</style>
